exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1URFTd{max-height:0px;overflow:hidden}._1QHX69{max-height:100px;transition:max-height 500ms ease-in-out}._2AO54E,._1tOo_Q{max-height:100px;overflow:hidden;transition:max-height .4s .2s ease-in-out}._34zi6y,.p2dCLx{max-height:0}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAEE,SACE,cAAA,CACA,eAAA,CAGF,SACE,gBAAA,CACA,uCAAA,CAGF,kBAEE,gBAAA,CACA,eAAA,CACA,yCAAA,CAGF,iBAEE,YAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n.transition {\n  &Enter {\n    max-height: 0px;\n    overflow: hidden;\n  }\n\n  &EnterActive {\n    max-height: 100px;\n    transition: max-height 500ms ease-in-out;\n  }\n\n  &Leave,\n  &Exit {\n    max-height: 100px;\n    overflow: hidden;\n    transition: max-height 0.4s 0.2s ease-in-out;\n  }\n\n  &LeaveActive,\n  &ExitActive {\n    max-height: 0;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"transitionEnter": "_1URFTd",
	"transitionEnterActive": "_1QHX69",
	"transitionLeave": "_2AO54E",
	"transitionExit": "_1tOo_Q",
	"transitionLeaveActive": "_34zi6y",
	"transitionExitActive": "p2dCLx"
};