exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".yyqzb3{position:relative;height:100%;width:100%;display:flex;flex-direction:column}._17zjJN{position:relative;height:100%;width:100%}._2nY1Re{position:absolute;height:100%;width:100%;top:0;left:0}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAGA,QACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CAEA,SACE,iBAAA,CACA,WAAA,CACA,UAAA,CAGF,SACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n@import \"../../../../scss/variables.scss\";\n\n.overdueTransition {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  &_body {\n    position: relative;\n    height: 100%;\n    width: 100%;\n  }\n\n  &_decoration {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    top: 0;\n    left: 0;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"colorPrimary": "#4ca94d",
	"overdueTransition": "yyqzb3",
	"overdueTransition_body": "_17zjJN",
	"overdueTransition_decoration": "_2nY1Re"
};