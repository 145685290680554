exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2cfPhT{font-size:100px !important}._3WnirK{height:100%;width:100%;display:flex;align-items:center;justify-content:center;border:4px solid #4ca94d}.rBZ7W3{border-radius:50%}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAGA,SACE,0BAAA,CAGF,SACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,wBAAA,CAGF,QACE,iBAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n@import \"../../../../../../scss/variables.scss\";\n\n.placeholderInnerIcon {\n  font-size: 100px !important;\n}\n\n.placeholder {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 4px solid $color-active;\n}\n\n.round {\n  border-radius: 50%;\n}\n"]}]);

// Exports
exports.locals = {
	"colorPrimary": "#4ca94d",
	"placeholderInnerIcon": "_2cfPhT",
	"placeholder": "_3WnirK",
	"round": "rBZ7W3"
};