exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2SjTtc{width:100%;margin:24px auto}._2SjTtc img{max-width:100%;margin:0 auto 8px;display:block}._2SjTtc._2IVE8i,._2SjTtc.Vd7hOZ{display:inline-block}._2SjTtc._2IVE8i{float:left;margin:0 24px 24px 0}._2SjTtc.Vd7hOZ{float:right;margin:0 0 24px 24px}._2SjTtc._1Z22au{max-width:400px}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAGA,SACE,UAAA,CACA,gBAAA,CAEA,aACE,cAAA,CACA,iBAAA,CACA,aAAA,CAGF,iCAEE,oBAAA,CAGF,iBACE,UAAA,CACA,oBAAA,CAGF,gBACE,WAAA,CACA,oBAAA,CAGF,iBACE,eAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n@import \"../../../../../../scss/variables.scss\";\n\n.articleImage {\n  width: 100%;\n  margin: $spacing-md auto;\n\n  img {\n    max-width: 100%;\n    margin: 0 auto $spacing-sm;\n    display: block;\n  }\n\n  &.-left,\n  &.-right {\n    display: inline-block;\n  }\n\n  &.-left {\n    float: left;\n    margin: 0 $spacing-md $spacing-md 0;\n  }\n\n  &.-right {\n    float: right;\n    margin: 0 0 $spacing-md $spacing-md;\n  }\n\n  &.-small {\n    max-width: 400px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"colorPrimary": "#4ca94d",
	"articleImage": "_2SjTtc",
	"-left": "_2IVE8i",
	"-right": "Vd7hOZ",
	"-small": "_1Z22au"
};