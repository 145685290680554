exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1ThBOT{position:relative;height:100%;width:100%}._21iGqT{height:100%}._2x6A3S{position:relative;min-height:100px;height:100%}._1Jk9PH{position:absolute;left:0;top:0;height:100%;width:100%}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAGA,SACE,iBAAA,CACA,WAAA,CACA,UAAA,CAGA,SACE,WAAA,CAGF,SACE,iBAAA,CACA,gBAAA,CACA,WAAA,CAGF,SAEE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n@import \"../../../../scss/variables.scss\";\n\n.protectedArea {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  // overflow: hidden;\n\n  &_container {\n    height: 100%;\n  }\n\n  &_localised {\n    position: relative;\n    min-height: 100px;\n    height: 100%;\n  }\n\n  &_fallback {\n    // HACK: ensures the content is shown correctly\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n    // z-index: 2;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"colorPrimary": "#4ca94d",
	"protectedArea": "_1ThBOT",
	"protectedArea_container": "_21iGqT",
	"protectedArea_localised": "_2x6A3S",
	"protectedArea_fallback": "_1Jk9PH"
};