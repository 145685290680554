exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1EA4Z8{text-transform:uppercase;color:gray;font-size:.7em}._1CoLYy,._1df_fw{float:right}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AAIE,SACE,wBAAA,CACA,UAAA,CACA,cAAA,CAGF,kBAEE,WAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n@import \"../../../../../scss/variables.scss\";\n\n.ManagerSalesOrderDetailsSidePanel {\n  &_orderType {\n    text-transform: uppercase;\n    color: grey;\n    font-size: 0.7em;\n  }\n\n  &_printBtn,\n  &_cancelPrintBtn {\n    float: right;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"colorPrimary": "#4ca94d",
	"ManagerSalesOrderDetailsSidePanel_orderType": "_1EA4Z8",
	"ManagerSalesOrderDetailsSidePanel_printBtn": "_1CoLYy",
	"ManagerSalesOrderDetailsSidePanel_cancelPrintBtn": "_1df_fw"
};