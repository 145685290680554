exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2ub3Xv{border-radius:3px;padding:3px 10px;display:flex;align-items:center}._2ub3Xv ._1eAt_X{flex:1}._2ub3Xv ._1eAt_X ._18NPqN{font-size:20px}._2ub3Xv ._1eAt_X ._2bdQzx{font-size:13px}._2ub3Xv ._3Kfcaq>span{display:flex;font-size:25px}._1yyx4C{background-color:rgba(20,20,20,.85);color:#fff;box-shadow:0 0 5px 0 #fff}._1yyx4C ._3Kfcaq>span{color:#fff}._38T66I{background-color:rgba(255,255,255,.85);color:#222}._38T66I ._3Kfcaq>span{color:#222}", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":"AACA,SACE,iBAAA,CACA,gBAAA,CAEA,YAAA,CACA,kBAAA,CAEA,kBACE,MAAA,CAEA,2BACE,cAAA,CAEF,2BACE,cAAA,CAIJ,uBACE,YAAA,CACA,cAAA,CAGF,SACE,mCAAA,CACA,UAAA,CACA,yBAAA,CAEA,uBACE,UAAA,CAIJ,SACE,sCAAA,CACA,UAAA,CAEA,uBACE,UAAA","file":"styles.scss","sourcesContent":["$theme: zam;\n.ToastSlice {\n  border-radius: 3px;\n  padding: 3px 10px;\n\n  display: flex;\n  align-items: center;\n\n  .textContainer {\n    flex: 1;\n\n    .title {\n      font-size: 20px;\n    }\n    .description {\n      font-size: 13px;\n    }\n  }\n\n  .iconContainer > span {\n    display: flex;\n    font-size: 25px;\n  }\n\n  &_dark {\n    background-color: rgba(20, 20, 20, 0.85);\n    color: #fff;\n    box-shadow: 0 0 5px 0 white;\n\n    .iconContainer > span {\n      color: #fff;\n    }\n  }\n\n  &_light {\n    background-color: rgba(255, 255, 255, 0.85);\n    color: #222;\n\n    .iconContainer > span {\n      color: #222;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"ToastSlice": "_2ub3Xv",
	"textContainer": "_1eAt_X",
	"title": "_18NPqN",
	"description": "_2bdQzx",
	"iconContainer": "_3Kfcaq",
	"ToastSlice_dark": "_1yyx4C",
	"ToastSlice_light": "_38T66I"
};